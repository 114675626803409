import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../api.service';

@Component({
    selector: 'app-div-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  bgColor:string;
  constructor(private apiService: ApiService) {}

    ngOnInit() {
      let isLight = this.apiService.getLocalStorageData('isLight');
      this.bgColor = (isLight)? '#333': 'white';
    }
}
