import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from "rxjs";
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  changeTheme = new Subject<any>();
  setThemeColor(color: any) {
    this.changeTheme.next(color);
  }

  getThemeColor(): Observable<any> {
    return this.changeTheme.asObservable();
  }


  apiURL: string = localStorage.getItem('apiURL');
  public data = true;
  public user_id = "";
  public lang = "";
  constructor(private httpClient: HttpClient, private _scrollToService: ScrollToService) {
    if(localStorage.getItem('user_id')) {
      this.user_id = "&userid="+localStorage.getItem('user_id');
    }
    this.lang = "&lang="+localStorage.getItem('locale');

  }

  /* =
     = clear localstorage
    ----------------------------------- */
    clearLocalStorage() {
      localStorage.clear();
    }

    /* =
       = remove localstorage key
      ----------------------------------- */
    removeLocalStorageData(key) {
      localStorage.removeItem(key);
    }

    /* =
       = set localstorage with key and value
      ------------------------------------------ */
    setLocalStorageData(key, value) {
      const enc = JSON.stringify(value);
      localStorage.setItem(key, window.btoa(unescape(encodeURIComponent(enc))));
    }

    /* =
       = Get the localstorage data using key
      ------------------------------------------ */
    getLocalStorageData(key) {
      if (localStorage.getItem(key)) {
        const dec = decodeURIComponent(
          escape(window.atob(localStorage.getItem(key)))
        );
        return JSON.parse(dec);
      } else {
        return null;
      }
    }




  public triggerScrollTo() {
    const config: ScrollToConfigOptions = {
      target: 'destination'
    };
    this._scrollToService.scrollTo(config);
  }

  getMyData() {
    return this.data;
  }
  setMydata(setdata:boolean) {
      this.data = setdata;
  }


  //Login API
  login(data: any) {
    return this.httpClient.get(this.apiURL+"action=login&"+data+this.lang);
  }

  //Dashboard API
  getPeriordReport() {
    return this.httpClient.get(this.apiURL+"apiaction=periord_report"+this.lang);
  }
  getGenderWithLocationActivity() {
    return this.httpClient.get(this.apiURL+"apiaction=genderwithlocation"+this.lang);
  }

  getGenderWithDivisionActivity() {
    return this.httpClient.get(this.apiURL+"apiaction=genderwithdivision"+this.lang);
  }

  getRightSideMenu(){
    return this.httpClient.get(this.apiURL+"apiaction=latestpost"+this.lang);
  }
  getCoachUserRatio() {
    return this.httpClient.get(this.apiURL+"apiaction=coach_user_ratio"+this.lang);
  }
  getUserWithCoach() {
    return this.httpClient.get(this.apiURL+"apiaction=user_with_coach"+this.lang);
  }
  getAvgNoConnection() {
    return this.httpClient.get(this.apiURL+"apiaction=average_number_of_connection"+this.lang);
  }
  getConnectionPerUser() {
    return this.httpClient.get(this.apiURL+"apiaction=connection_per_user"+this.lang);
  }
  getUserPerPhase() {
    return this.httpClient.get(this.apiURL+"apiaction=user_per_phase"+this.lang);
  }
  getTotalVisitors() {
    return this.httpClient.get(this.apiURL+"apiaction=visitors"+this.lang);
  }
  getFilterLocation() {
    return this.httpClient.get(this.apiURL+"apiaction=get_locations"+this.lang);
  }
  getFilterDivision() {
    return this.httpClient.get(this.apiURL+"apiaction=division"+this.lang);
  }
  getCourseStatus() {
    return this.httpClient.get(this.apiURL+"apiaction=course_status"+this.lang);
  }
  getCourseFilter(id:any) {
    return this.httpClient.get(this.apiURL+"apiaction=coursefilter&courseId="+id+this.lang);
  }
  getFilter(text: any) {
    return this.httpClient.get(this.apiURL+"apiaction=dfilter&"+text+this.lang);
  }
  getAssessment() {
    return this.httpClient.get(this.apiURL+"apiaction=getassessment"+this.lang);
  }
  getAssessmentFilter(userId: any) {
    return this.httpClient.get(this.apiURL+"apiaction=getassessmentfilter&userid="+userId+this.lang);
  }
  getUserEgg() {
    return this.httpClient.get(this.apiURL+"apiaction=getusersegg"+this.lang);
  }
  getHappyMeterData() {
    return this.httpClient.get(this.apiURL+"apiaction=dashboard_happy_meter"+this.lang);
  }

  //Social page API
  getSocialConnection() {
    return this.httpClient.get(this.apiURL+"apiaction=socialconnection"+this.lang);
  }
  getLocationConnection() {
    return this.httpClient.get(this.apiURL+"apiaction=locationconnection"+this.lang);
  }
  getDivisionConnection() {
    return this.httpClient.get(this.apiURL+"apiaction=divisionconnection"+this.lang);
  }
  getSocialConnectionFilters(text: any) {
    return this.httpClient.post(this.apiURL+"apiaction=filter_socialconnection", text);
  }
  getLeaderBoardData() {
    return this.httpClient.get(this.apiURL+"apiaction=leaderboarddivloc"+this.lang);
  }

  //Profile page API
  getProfileUser() {
    return this.httpClient.get(this.apiURL+"profileaction=getusers"+this.lang);
  }
  getUserActivity() {
    return this.httpClient.get(this.apiURL+"profileaction=getuseractivity"+this.user_id+this.lang);
  }
  getUserConnection() {
    return this.httpClient.get(this.apiURL+"profileaction=getuserconnection"+this.user_id+this.lang);
  }
  getUserCourses() {
    return this.httpClient.get(this.apiURL+"profileaction=getusercourse"+this.user_id+this.lang);
  }
  getUserCoaches() {
    return this.httpClient.get(this.apiURL+"profileaction=getusercoaches"+this.user_id+this.lang);
  }
  getUserGroups() {
    return this.httpClient.get(this.apiURL+"profileaction=getgroups"+this.user_id+this.lang);
  }
  getMostUsedItem() {
    return this.httpClient.get(this.apiURL+"profileaction=getusermostuseditem"+this.user_id+this.lang);
  }
  getTypeOfConnection() {
    return this.httpClient.get(this.apiURL+"profileaction=getusertypeofconnection"+this.user_id+this.lang);
  }
  getProfileFilters(text: any) {
    return this.httpClient.get(this.apiURL+"profileaction=getprofilefilter"+this.user_id+"&"+text+this.lang);
  }
  getProfileActivityFilter(text: any) {
    return this.httpClient.get(this.apiURL+"profileaction=getprofileactivityfilter"+this.user_id+"&"+text+this.lang);
  }
  getProfileEgg() {
    return this.httpClient.get(this.apiURL+"profileaction=getprofileegg"+this.user_id+this.lang);
  }
  getProfileAssessment() {
    return this.httpClient.get(this.apiURL+"profileaction=getprofileassement"+this.user_id+this.lang);
  }
  getUserHappyMeterData() {
    return this.httpClient.get(this.apiURL+"profileaction=getuserhappymeter"+this.lang);
  }
  getPhasePointGain() {
    return this.httpClient.get(this.apiURL+"profileaction=getphasepointgain"+this.user_id+this.lang);
  }
  getProfileSocial() {
    return this.httpClient.get(this.apiURL+"profileaction=getprofilesocial"+this.user_id+this.lang);
  }
  getTrophyData() {
    return this.httpClient.get(this.apiURL+"profileaction=getprofiletrophy"+this.user_id+this.lang);
  }
  //Settings API
  settings(data: any) {
    return this.httpClient.post(this.apiURL+"action=profileupdate", data);
  }
}

